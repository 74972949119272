@import "../variables.less";

.landing-contact-us {

	.landing-contact-us-header {
		font-size: 30px;
		margin: 0 0 30px;
		font-weight: 300;
	}

	form {
		.field-label {
			font-weight: 500;
			font-size: 18px;
			margin-top: 1em;
			margin-bottom: .5em;
		}

		input.field-error, textarea.field-error {
			background: rgba(218, 129, 129, 0.09);
			border-color: #ba6666;
		}
	}

	.form-errors {
		background: rgba(218, 129, 129, 0.09);
		border: 2px solid #ba6666;
		border-radius: 6px;
		width: 100%;
		color: #ba6666;
	}

	.form-errors h6 {
		color: #ba6666;
		padding: .5em 0 0 .5em;
	}

	.form-success h4 {
		font-size: 19px;
		font-style: italic;
		color: @brand-green;
	}

	.btn.green {
		margin-top: 1.5em;
		background: @brand-green;
		color: white;
		padding: .5em 2.75em;
		font-weight: 400;

		&:hover {
			opacity: .9;
		}
	}

	.additional {
		h3 {
			font-weight: 400;
		}
		p {
			font-size: 1.3em;
			b {
				font-weight: 400;
			}
		}
	}

	.lg-phone-number {
		color: @brand-green;
		font-weight: 700;
	}
}