@import "../variables.less";

.landing-about-us-intro {
	background-color: @brand-lightest-purple;
	height: 550px;

	.title {
		font-weight: 300;
	}

	.landing-about-us-intro-background {
		background: url("../images/handshake.jpg") no-repeat fixed;
		opacity: .2;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background-size: auto 550px;
	}

	@media (min-width: 850px) {
		.landing-about-us-intro-background  {
			background-size: 100% auto;
		}
	}
}