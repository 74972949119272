@import "../variables.less";

.landing-intro {
	background-color: @brand-lightest-blue;
	height: 550px;

	.title {
		font-weight: 300;
	}

	.landing-intro-background {
		background-size: 100% 100%;
		background: url("../images/facilitize-header-bg.jpg") no-repeat fixed;
		opacity: .2;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background-size: auto 550px;
	}

	@media (min-width: 850px) {
		.landing-intro-background {
			background-size: 100% auto;
		}
	}
}

