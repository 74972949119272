@import "../variables.less";

.landing-about-us {
	.about-us-info .row {
		padding: 5em 0;
	}

	h2 {
		font-weight: 300;
	}

	p {
		color: #4A4A4A;
		font-size: 16px;
		line-height: 1.6;
	}

	.facilitize-logo-icon {
		margin-top: 4em;
	}
}