@import "../variables.less";

.landing-testimonials {
	padding-top: 5em;
	padding-bottom: 4.5em;
	background: @brand-lighter-green;

	.testimonials-header {
		font-size: 30px;
		font-weight: 300;
		color: #2c3e50;
		margin-bottom: 1em;
	}

	.quote-row {
		margin-bottom: 2.5em;
	}

	.quote {
		color: white;
		margin-top: .5em;
		font-style: italic;
		font-size: 1.6em;
	}

	.quote-author-info {
		color: white;
		margin-top: 1em;
		padding-left: 2em;
	}

	.quote-author {
		padding-top: 5px;
		font-size: 1em;
	}
}