@import "../variables.less";

.landing-video {
	text-align: center;

	.player {
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		border-radius: 6px;
		background: rgba(52, 152, 219, 0.1);
		position: relative;
		overflow: hidden;
		width: auto;
		height: 580px;
		margin: 40px auto 105px;

		iframe {
			width: 100%;
			height: 100%;
			border: 0;
			display: block;
		}
	}

	@media (max-width: 1200px) {
		.player {
			width: 100%;
			height: 530px;
		}
	}
	@media (max-width: 1000px) {
		.player {
			height: 406px;
		}
	}
	@media (max-width: 840px) {
		.player {
			height: 406px;
		}
	}
	@media (max-width: 768px) {
		.player {
			max-height: 330px;
		}
	}
	@media (max-width: 568px) {
		.player {
			max-height: 280px;
		}
	}
	@media (max-width: 400px) {
		.player {
			max-height: 180px;
		}
	}}