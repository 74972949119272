@import "../variables.less";

.landing-call-to-action {
	padding-top: 75px;
	padding-bottom: 75px;
	background-color: @brand-darkest-purple;

	.bg-image {
		background: url("../images/gears-blueprint-transparent.png");
		background-size: 100% auto;
		opacity: .1;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.block-content {
		z-index: 5;
		position: relative;
	}

	.btn {
		&.btn-gigantic {
			width: 90%;
			height: 150px;
			margin: .5em;
			font-size: 1.5em;
			white-space: normal;

			&:hover {
				opacity: .9;
			}

			&.blue {
				background: @brand-blue;
				color: white;
			}

			&.green {
				background: @brand-green;
				color: white;
			}
		}
	}

	h3 {
		color: white;
	}
}