@import "../variables.less";

.navbar {
	position: absolute;
	&.app-navbar-fixed {
		position: fixed;
	}
	&.navbar-smaller {
		padding-top: 5px;
		padding-bottom: 5px;
		.navbar-brand img {
			padding-top: 10px;
			width: 100px;
			height: auto;
		}
	}
	&.blue-bg {
		background: @brand-lightest-blue
	}
	&.purple-bg {
		background: @brand-lightest-purple;
	}
	&.green-bg {
		background: @brand-lightest-green;
	}
	&.gray-bg {
		background: @brand-lightest-gray;
	}
}

@media (max-width: 768px) {
	.navbar-padded {
		padding-top: 15px;
	}
}

.stage-shelf {
	position: fixed;
}

.navbar-brand {
	padding: 0;
	margin-top: -12px;
}

.navbar-nav {
	font-weight: 400;
}
