@import "../variables.less";

.landing-features {
	padding-top: 5em;
	padding-bottom: 4.5em;

	.feature-row {
		padding: 1.25em 0;
	}

	.features-header {
		font-size: 30px;
		font-weight: 300;
	}

	.feature-icon {
		font-size: 7.5em;
		text-align: center;
		color: @brand-gray;
	}

	@media (max-width: 1199px) {
		.feature-icon {
			font-size: 5em;
			text-align: center;
		}
	}

	@media (max-width: 600px) {
		.feature-icon {
			font-size: 4em;
			text-align: center;
		}
	}

	.feature-details {
		h4 {
			font-weight: 400;
		}
		p {
			font-size: 16px;
			font-weight: normal;
			color: @brand-lighter-gray;
			line-height: 26px;
		}
	}

	.feature-link {
		&:hover, &:focus {
			text-decoration: none;
			h4, p, .feature-icon {
				color: @brand-darker-blue;
			}
		}
		.feature-icon:hover, .feature-icon:focus, .feature-details:hover, .feature-details:focus {
			//color: @brand-blue;
		}
		.feature-details {
			h4:hover, h4:focus, p:hover, p:focus {
				//color: @brand-blue;
			}
		}
	}

}

.feature-section {
	.row {
		padding-top: 1em;
		padding-bottom: 1em;
	}
	.feature-image {
		-webkit-box-shadow: 2px 3px 2px -1px rgba(0,0,0,0.22);
		-moz-box-shadow: 2px 3px 2px -1px rgba(0,0,0,0.22);
		box-shadow: 2px 3px 2px -1px rgba(0,0,0,0.22);
	}
	.feature-description {
		padding-bottom: 1em;
	}
	.feature-header {
		padding-top: 2em;
		padding-bottom: .25em;
		font-size: 1.1em;
		font-weight: bold;
		&.centered {
			padding-top: .5em;
		}
	}
}

.feature-section-header {
	&.blue-bg {
		background: rgba(158, 185, 213, 0.25);
	}
	&.purple-bg {
		background: rgba(169, 166, 219, 0.25);
	}
	&.green-bg {
		background: rgba(154, 215, 195, 0.25);
	}
	&.gray-bg {
		background: rgba(206, 208, 211, 0.25);
	}
	.feature-header-row {
		padding-bottom: 1.5em;
		.feature-title {
			padding-bottom: 1em;
			.feature-name {
				font-size: 2.5em;
				color: #2D3238;
			}
		}
	}
}

@media (min-width: 768px) {
	.feature-section {
		.row {
			.normal-screen {
				display: block;
			}
			.mobile-screen {
				display: none;
			}
		}
	}
}

@media (max-width: 767px) {
	.feature-section {
		.row {
			.normal-screen {
				display: none;
			}
			.mobile-screen {
				display: block;
			}
		}
	}
}