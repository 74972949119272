@import "../variables.less";

.landing-footer {
	background: @brand-darker-blue;
	padding: 2em 0;

	.footer-address {
		color: #b2b2b2;
	}

	ul {
		list-style-type: none;
		padding-left: 0.5em;
	}

	nav {
		h4 {
			color: #e0e0e0;
			font-weight: 300;
		}
		a {
			color: #b2b2b2;
		}
	}
}