@import "./variables.less";

// text overrides
@font-size-base: 15px;
@font-family-sans-serif: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
@text-color: #6C6F73;
@headings-color: #2D3238;
@line-height-base: 1.4;

// nav tranpsarent
@navbar-transparent-brand-hover-bg:      transparent;
@navbar-transparent-toggle-hover-bg:     transparent;
@navbar-transparent-toggle-border-color: transparent;

// nav bordered
@nav-bordered-color:              #000;
@nav-bordered-color-active:       @brand-primary;
@nav-bordered-font-weight:        300;
@nav-bordered-font-weight-active: 700;

// brand
@brand-primary: #2595FF;

// lead
@lead-letter-spacing: @letter-spacing-base;
@lead-font-size:      floor((@font-size-base * 1.5));

// blocks
@block-title-font-size-sm: floor((@font-size-base * 2.8));
@block-title-font-size:    floor((@font-size-base * 2.5));
@block-lead-font-size-sm:  floor((@font-size-base * 1.4));
@block-vertical-padding:   100px;



@import "./components.less";

.col-centered {
	text-align: center;
}